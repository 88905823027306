import React from 'react'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const ContactCTAContent = styled.div`
  min-height: 220px;

  @media screen and (max-width: 991px) {
    min-height: 100px;
  }
`

const ContactCTARow = styled.div`
  margin-top: -164px;

  @media screen and (max-width: 991px) {
    margin-top: 0;
  }

  h3 {
    font-size: ${props => props.theme.font.size.ml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xm};
    }
  }
`;

const ContactCTA = ({ className, content }) => {
  return (
    <div className={`pl-lg-3 ${className ? `${className}` : ``}`}>
      <ContactCTARow className="row justify-content-lg-end justify-content-center mb-5">
        <div className="col-lg-9">
          <ContactCTAContent className="d-flex flex-column justify-content-center align-items-center color-background-lightGrey px-4 p-4">
            <h3 className="color-text-contrast text-center font-weight-light mb-lg-5 mb-4 pb-lg-4">{content.title}</h3>
            <ButtonDefault className="mb-lg-3" to={content.link.url}>
              {content.link.title}
            </ButtonDefault>
          </ContactCTAContent>
        </div>
      </ContactCTARow>
    </div>
  )
}

export default ContactCTA
