import React from 'react'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledVacatureDetailContent = styled.div`
  .p3 {
    margin-top: 40px;
  }

  & ol {
    & li {
      list-style: inherit;

      &::before {
        display: none;
      }
    }
  }

  @media (max-width: 991px){
    & h2 {
      font-size: 1rem;
    }
  }
`

const H3 = styled.h3`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.contrast};
  padding-top: 15px;
  margin-bottom: 3px;
`

const LI = styled.li`
  list-style: none;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;

  &::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background-color: ${props => props.theme.color.main};
    width: 10px;
    height: 10px;
  }
`

const VacatureDetailContent = ({ className, content }) => {
  return (
    <StyledVacatureDetailContent className={`${className ? `${className}` : ``}`}>
      {parse(content, {
        replace: (domNode) => {


          if (domNode.name === 'h3') {
            return <H3>{domNode.children[0].data}</H3>
          }

          if (domNode.name === 'li') {
            return <LI>{domNode.children[0].data}</LI>
          }



          return true
        }
      })}
    </StyledVacatureDetailContent>
  )
}

export default VacatureDetailContent
