/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import AboutUs from 'components/shared/before-footer/AboutUs'
import ContactCTA from 'components/ContactCTA'
import GravityForm from 'components/GravityForm'
import VacatureDetailContent from 'components/elements/VacatureDetailContent'

// Third Party
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'

// Helpers
import { getLanguage } from 'services/language'

const StyledVacatureDetail = styled.div``

const DetailTitle = styled.h2`
  position: relative;
  left: -50px;
  font-size: ${props => props.theme.font.size.xl};
  width: 80%;

  @media screen and (max-width: 991px) {
    left: 0;
    width: 100%;
    font-size: ${props => props.theme.font.size.xl};
  }
`
const FormContainer = styled.div`
  background-color: ${props => props.theme.color.grey};
`

const FormTitle = styled.h2`
  color: ${props => props.theme.color.text.light};
`

const StyledPlayer = styled(ReactPlayer)`
  position: relative;
  background-color: #000000;
  height: 100%;
  width: 100%;
`

const PlayerContainer = styled.div`
  width: 530px;
  height: 300px;

  position: relative;
  right: -65px;

  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: 250px;
    right: 0;
  }
`

const VacatureDetailTemplate = ({
  data: {
    page: {
      title,
      seo,
      singleVacancy
    },
  },
}) => {
  const language = getLanguage()
  return (
    <Layout>
      {seo && (
        <SEO
          seo={seo}
          socialImage={singleVacancy.banner.image.localFile.childImageSharp.meta.src}
        />
      )}

      <Hero src={singleVacancy.banner.image} title={singleVacancy.banner.title} />

      <StyledVacatureDetail className="container py-5">
        <div className="row mb-lg-4">
          <div className="col-lg-6">
            <DetailTitle className="pb-5">{singleVacancy.description.title}</DetailTitle>
            {singleVacancy.description.introduction && (
              <VacatureDetailContent content={singleVacancy.description.introduction} className="pl-lg-5 pb-5 pb-lg-0" />
            )}
            {!singleVacancy.description.introduction && (
              <VacatureDetailContent content={singleVacancy.description.content} className="pl-lg-5 pb-5 pb-lg-0" />

            )}
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <ContactCTA content={singleVacancy.contact} />
            {singleVacancy.video.url !== 'empty' && (
              <PlayerContainer className="mb-5">
                <StyledPlayer width="100%" height="100%" controls url={singleVacancy.video.url} />
              </PlayerContainer>
            )}
          </div>
        </div>
        {singleVacancy.description.introduction && (
          <div className="row">
            <div className="col-lg-10">
              <VacatureDetailContent content={singleVacancy.description.content} className="pl-lg-5 pb-5 pb-lg-0" />
            </div>
          </div>
        )}
        <FormContainer className="mx-lg-5 py-5 my-lg-5">
          <FormTitle className="text-center pb-3">{singleVacancy.form.title}</FormTitle>
          <div className="col-lg-8 px-lg-0 px-lg-5 mx-auto">
            {language === 'nl_NL' ? (
              <GravityForm vacature id={2} defaultValues={{
                'input_8': title
              }} />
            ) : (
              <GravityForm vacature id={4} defaultValues={{
                'input_8': title
              }} />
            )}
          </div>
        </FormContainer>
        <div className="col-lg-6 d-lg-none px-0 pt-5">
          <ContactCTA content={singleVacancy.contact} />
          {singleVacancy.video.url !== 'empty' && (
            <PlayerContainer className="mb-5">
              <StyledPlayer width="100%" height="100%" controls url={singleVacancy.video.url} />
            </PlayerContainer>
          )}
        </div>
      </StyledVacatureDetail>
      <AboutUs />
    </Layout>
  )
}

export default VacatureDetailTemplate

export const pageQuery = graphql`
  query($id: String) {
    page: wpVacature(id: { eq: $id }) {
      title
      uri
      singleVacancy {
        fieldGroupName
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }
        description {
          title
          introduction
          content
        }
        form {
          title
        }
        contact {
          title
          link {
            title
            url
            target
          }
        }
        video {
          url
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
